import React from "react";

import "./legend.css";

const Legend = () => {
  return (
    <div id="home-legend">
      <div className="card text-center bg-dark" style={{ margin: "0", padding: '0px', boxShadow: "8px 0.5vw 6px rgb(0 0 0)", border: '0' }}>
        <button
          className="btn btn-dark"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseExample"
          data-bs-parent="#home-legend"
          aria-expanded="false"
          aria-controls="collapse"
        >
          <h2 className="legend-title">- Legend -</h2>
          <p className="legend-card-header__click-to-expand">
            (click here to expand)
          </p>
        </button>

        <div className="collapse" id="collapseExample">
          <div className="card-body">
            <div className="row legend-element">
              <div className="col-3">
                <img className="legend-image" src="https://storage.googleapis.com/callouts_tf_resources/public_images/icons/line-sample-1.png" alt="" />
              </div>
              <div className="col-2">
                <h1 className="equal-sign">=</h1>
              </div>
              <div className="col-7">
                <p className="legend-description">
                  Unsurpassable obstacles,{" "}
                  <span className="legend-description-light">
                    walls, dead ends
                  </span>
                </p>
              </div>
            </div>
            <hr className="mx-auto legend-hr" />
            <div className="row legend-element">
              <div className="col-3">
                <img className="legend-image" src="https://storage.googleapis.com/callouts_tf_resources/public_images/icons/line-sample-2.png" alt="" />
              </div>
              <div className="col-2">
                <h1 className="equal-sign">=</h1>
              </div>
              <div className="col-7">
                <p className="legend-description">
                  Height differences,{" "}
                  <span className="legend-description-light">
                    ledges, windows, catwalks, platforms
                  </span>
                </p>
              </div>
            </div>
            <hr className="mx-auto legend-hr" />
            <div className="row legend-element">
              <div className="col-3">
                <img className="legend-image" src="https://storage.googleapis.com/callouts_tf_resources/public_images/icons/line-sample-3.png" alt="" />
              </div>
              <div className="col-2">
                <h1 className="equal-sign">=</h1>
              </div>
              <div className="col-7">
                <p className="legend-description">
                  Same-height divisions,{" "}
                  <span className="legend-description-light">
                    doors, ambiguous separation of terrain
                  </span>
                </p>
              </div>
            </div>
            <hr className="mx-auto legend-hr" />
            <div className="row legend-element">
              <div className="col-3">
                <img className="legend-image" src="https://storage.googleapis.com/callouts_tf_resources/public_images/icons/line-sample-4.png" alt="" />
              </div>
              <div className="col-2">
                <h1 className="equal-sign">=</h1>
              </div>
              <div className="col-7">
                <p className="legend-description">
                  <span className="legend-description-light">
                    'Under' callout is located{" "}
                  </span>
                  beneath an obstacle
                </p>
              </div>
            </div>

            <hr className="mx-auto legend-hr" />
            <div className="row legend-element">
              <div className="col-3">
                <img className="legend-image" src="https://storage.googleapis.com/callouts_tf_resources/public_images/icons/line-sample-5.png" alt="" />
              </div>
              <div className="col-2">
                <h1 className="equal-sign">=</h1>
              </div>
              <div className="col-7">
                <p className="legend-description">
                  <span className="legend-description-light">
                    'Obstacle' can be{" "}
                  </span>
                  surpassed from below
                </p>
              </div>
            </div>

            <hr className="mx-auto legend-hr" />
            <div className="row legend-element">
              <div className="col-3">
                <img className="legend-image" src="https://storage.googleapis.com/callouts_tf_resources/public_images/icons/line-sample-6.png" alt="" />
              </div>
              <div className="col-2">
                <h1 className="equal-sign">=</h1>
              </div>
              <div className="col-7">
                <p className="legend-description">
                  <span className="legend-description-light">
                    New layer is{" "}
                  </span>
                  at a lower height than parent layer
                </p>
              </div>
            </div>

            <hr className="mx-auto legend-hr" />
            <div className="row legend-element">
              <div className="col-3">
                <img className="legend-image" src="https://storage.googleapis.com/callouts_tf_resources/public_images/icons/line-sample-7.png" alt="" />
              </div>
              <div className="col-2">
                <h1 className="equal-sign">=</h1>
              </div>
              <div className="col-7">
                <p className="legend-description">
                  <span className="legend-description-light">
                    New layer is{" "}
                  </span>
                  at a greater height than parent layer
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Legend;
