import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Partner = ({ partnerData }) => {
    return (
        <div className="col">
            <div>
                <a href={partnerData.link}>
                    <img className="partner_banner" src={partnerData.banner} alt={partnerData.name} title={partnerData.name} />
                </a>
            </div>
            {partnerData.main_ambassador && partnerData.main_ambassador.name !== "-" && (
                <p className="partner_subtitle">
                    via {partnerData.main_ambassador.userObjectId ? (
                        <Link to={"/users/" + partnerData.main_ambassador.userObjectId.snakeUsername}>
                            {partnerData.main_ambassador.userObjectId.username}
                        </Link>
                    ) : (
                        <a href={`https://steamcommunity.com/profiles/${partnerData.main_ambassador.steamID}`}>
                            {partnerData.main_ambassador.name}
                        </a>
                    )}
                    {partnerData.other_ambassadors.length > 0 && (
                        <button
                            id="ambassadors-expand-button"
                            className="btn btn-outline-light btn-sm"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#${partnerData.name}`}
                            data-bs-parent="#strike-card"
                            aria-expanded="false"
                            aria-controls="collapse"
                        >
                            <p id="strike-expand-button-text">+{partnerData.other_ambassadors.length} more</p>
                        </button>
                    )}
                </p>
            )}
            {partnerData.other_ambassadors.length > 0 && (
                <div className="collapse" id={partnerData.name}>
                    <div className="secondary_ambassadors">
                        {partnerData.other_ambassadors.map((ambassador, index) => (
                            <a href={`https://steamcommunity.com/profiles/${ambassador.steamID}`}>
                                <p>
                                    {index + ") " + ambassador.name}
                                </p>
                            </a>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}

export default Partner;