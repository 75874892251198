import React from "react";
import { NavLink } from "react-router-dom";

import { BiSolidMegaphone } from "react-icons/bi";
import { IoMdHeart } from "react-icons/io";
import { FaEnvelopeOpenText } from "react-icons/fa6";
import { IoInformationCircle } from "react-icons/io5";

import "./NavLinks.css";

const NavLinks = ({ nomCount }) => {
  return (
    <React.Fragment>
      <div className="row justify-content-center center nav-links">

        <div className="col-auto">
          <div className="row">
            <NavLink to="/nominations" exact>
              <div className="col-12 icon-col">
                <BiSolidMegaphone className="icon" />
              </div>
              <div className="col-12">
                <p className="nav_subtitle unselectable">Nominations {nomCount > 0 && "(" + nomCount + ")"}</p>
              </div>
            </NavLink>
          </div>
        </div>

        <div className="col-auto">
          <div className="row">
            <NavLink to="/donations" exact>
              <div className="col-12 icon-col">
                <IoMdHeart className="icon" />
              </div>
              <div className="col-12">
                <p className="nav_subtitle unselectable">Donations</p>
              </div>
            </NavLink>
          </div>
        </div>

        <div className="col-auto">
          <div className="row">
            <NavLink to="/termsofuse" exact>
              <div className="col-12 icon-col">
                <FaEnvelopeOpenText className="icon" />
              </div>
              <div className="col-12">
                <p className="nav_subtitle unselectable">Guidelines</p>
              </div>
            </NavLink>
          </div>
        </div>

      </div>
    </React.Fragment>










  );
};

export default NavLinks;
