import React, { useEffect } from "react";

import MapItem from "./MapItem";

import "./MapList.css";

const MapsList = (props) => {
  useEffect(() => {
    props.setTotalItemsFound(props.searchAndFilter(props.loadedMapsData).length);
  }, [props])

  const finalRenderedMaps = props.searchAndFilter(props.loadedMapsData).slice(Number(props.sliceIndicators), Number(props.sliceIndicators) + 12);


  if (!props.loadedMapsData || props.loadedMapsData.length === 0) {
    return (
      <div className="empty_container">
        <img className="empty" src="https://storage.googleapis.com/callouts_tf_resources/public_images/empty_section_filler/map.png"
          alt="map_filler_empty" />
        <h3 className="empty_list_fill">No maps to show yet...</h3>
      </div>
    )
  } else if (! finalRenderedMaps || finalRenderedMaps.length === 0) {
    return (
      <div className="empty_container">
        <img className="empty" src="https://storage.googleapis.com/callouts_tf_resources/public_images/empty_section_filler/map.png"
          alt="map_filler_empty" />
        <h3 className="empty_list_fill">No maps meet your criteria!</h3>
      </div>
    )
  } else if (finalRenderedMaps.length > 0) {
    return (
      <div className="row justify-content-center row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xxl-4">
        {finalRenderedMaps.map((mapData) => (
          <MapItem
            key={mapData.id}
            mapid={mapData.id}
            usage={mapData.mapUsage.flat()}
            image={mapData.thumbnail}
            name={mapData.name}
            // date={mapData.date_created.split(',')[0]}
            version_count={mapData.callout_versions.length}
            languages={mapData.languages}
            views={mapData.views}
            downloads={mapData.downloads}
            upvotes={mapData.upvotes}
            comments={mapData.comments.length}
            loggeduserupvotedmaps={props.loggeduserupvotedmaps}
          />
        ))}
        
      </div>
    )
  }

}

export default MapsList;