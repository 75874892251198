import React, { useState, useEffect } from "react";

import Partner from "./partner";

import { Link } from "react-router-dom";

import "./footer.css";

import { useHttpClient } from "../../../shared/hooks/http-hook";

const Footer = ({ user }) => {

  const [partners, setPartners] = useState();
  const { isLoading, sendRequest } = useHttpClient()

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/collective/partners", "GET", null, "include"
        );
        setPartners(responseData.partners);
      } catch (err) {
        console.log(err)
      }
    };
    fetchUser();
  }, [sendRequest]);

  let date = new Date();
  const thisYear = date.getFullYear();

  function sortPartners(partners) {
    return partners.sort(
      (a, b) => parseFloat(a.number) - parseFloat(b.number)
    )
  }

  return (
    <React.Fragment>
      <footer id="footer">

        <div id="footer_calloutstf_section">
          <Link to={"/"}>
            <img id="callouts_tf_banner" src="https://storage.googleapis.com/callouts_tf_resources/public_images/logos/callouts.tf%20banner.png" alt="callouts.tf_banner" />
          </Link>
        </div>

        {!isLoading && partners && partners.length && (
          <div id="footer_partners_section">
            <h4 id="partners-title">Our partners:</h4>
            <div id="partners">
              <div className="container">
                <div className="row justify-content-center row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xxl-4">
                  {sortPartners(partners).map((partnerData) => (
                    <Partner
                      partnerData={partnerData}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}

        <div id="linksmenu" className="container">
          <div className="row justify-content-center">
            <div className="col-auto linksmenu_link">
              <Link to={"/termsofuse"}>
                Terms of Use
              </Link>
            </div>
            <div className="col-auto linksmenu_link">
              <Link to={"/changelog"}>
                changelog
              </Link>
            </div>
            <div className="col-auto linksmenu_link">
              <Link to={"/about"}>
                contact
              </Link>
            </div>
          </div>
        </div>

        <div id="footer_bottom_contents">
          <p>
            {thisYear === 2024 ? (
              <span>@2024 </span>
            ) : (
              <span>@2024 - {thisYear} </span>
            )}
            ~ powered by
            <a href="https://store.steampowered.com/">
              <span id="powered-by-steam"> Steam® </span>
            </a>
            <span />
            <span >
              ~ v1.3
            </span>
          </p>
          <p id="author-section"> by <span />
            <a href="https://steamcommunity.com/id/java59/">
              <span id="java59">
                Java59
              </span>
            </a>
          </p>
        </div>
      </footer>

    </React.Fragment>
  );
};

export default Footer;
