import React, { useEffect, useState, Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";

import LoadingSpinner from "./shared/components/UIElements/LoadingSpinner";

import MainNavigation from './shared/components/Navigation/MainNavigation';
import Footer from './shared/components/Footer/footer';

import Mainpage from "./unshared/mainpage/pages/mainpage";

import { useHttpClient } from "./shared/hooks/http-hook";

const AdminMenu = React.lazy(() => import("./unshared/admin/pages/admin-menu"));
const SingleMap = React.lazy(() => import("./unshared/singlemap/pages/singlemap"));
const Profile = React.lazy(() => import("./unshared/profile/pages/profile"));
const Nominations = React.lazy(() => import("./unshared/forums/pages/forums"));
const Nomination = React.lazy(() => import("./unshared/forums/pages/entry"));
const Donations = React.lazy(() => import("./unshared/donations/pages/donations"));
const About = React.lazy(() => import("./unshared/info/pages/about"));
const Changelog = React.lazy(() => import("./unshared/info/pages/changelog"));
const ToU = React.lazy(() => import("./unshared/info/pages/terms"));
const TF2Maps = React.lazy(() => import("./unshared/info/pages/tf2maps"));

const App = () => {
  const [user, setUser] = useState();
  const [authorized, setAuthorized] = useState();
  const [sudoAuthorized, setSudoAuthorized] = useState();
  const [userDeep, setUserDeep] = useState();
  const [ipuser, setIpuser] = useState();

  const { isLoading, sendRequest } = useHttpClient()

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/users/auth/login/success", "GET", null, "include"
        );
        setUser(responseData.user);
        setAuthorized(responseData.authorized);
        setSudoAuthorized(responseData.owner);
      } catch (err) { }
    };

    const fetchDeepUser = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/users/auth/deepuser/return", "GET", null, "include"
        );
        setUserDeep(responseData.deepUser);
      } catch (err) { }
    };
    fetchUser();
    fetchDeepUser();

    // const fetchIPuser = async () => {
    //   try {
    //     const responseData = await sendRequest(
    //       process.env.REACT_APP_BACKEND_URL + "/users/ipuser", "GET", null, "include"
    //     );
    //     setIpuser(responseData.ipuser);
    //   } catch (err) { }
    // };
    fetchUser();
    fetchDeepUser();
    // fetchIPuser();

  }, [sendRequest]);

  let routes;

  if (!isLoading) {
    routes = (
      <Switch>
        <Route path="/" exact>
          <Mainpage loggeduserdeep={userDeep} />
        </Route>
        <Route path="/users/:snakeName" exact>
          <Profile loggeduser={user} loggeduserdeep={userDeep} />
        </Route>
        <Route path="/admin" exact>
          <AdminMenu authorized={authorized} sudoAuthorized={sudoAuthorized} admin={user} />
        </Route>
        <Route path="/maps/:mapName" exact>
          <SingleMap loggeduser={user} loggeduserdeep={userDeep} ipuser={ipuser} />
        </Route>
        <Route path="/nominations" exact>
          <Nominations loggeduser={user} loggeduserdeep={userDeep} ipuser={ipuser} />
        </Route>
        <Route path="/nominations/:nomNumber" exact>
          <Nomination loggeduser={user} loggeduserdeep={userDeep} ipuser={ipuser} />
        </Route>
        <Route path="/donations" exact>
          <Donations loggeduser={user} />
        </Route>
        <Route path="/termsofuse" exact>
          <ToU />
        </Route>
        <Route path="/about" exact>
          <About />
        </Route>
        <Route path="/changelog" exact>
          <Changelog />
        </Route>
        <Route path="/tf2maps" exact>
          <TF2Maps />
        </Route>
        <Redirect to="/" />
      </Switch>
    )
  }

  // Maybe change this on production: style={{ marginTop: '0px' }}
  return (
    <Router>
      {user ? (<MainNavigation user={user} ipuser={ipuser} />) : (<MainNavigation />)}
      <main style={{ marginTop: '-35px' }}> <Suspense fallback={<div className="center"><LoadingSpinner /></div>} >{routes}</Suspense></main>
      <Footer />
    </Router>
  );
};

export default App;
