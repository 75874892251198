import React from 'react';
import Modal from 'react-modal';

import "./sign-in-modal.css"

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center'
  },
};

function SignInModal(props) {
  return (
    <div>
      <Modal
        ariaHideApp={false}
        isOpen={props.isOpen}
        onRequestClose={props.onRequestClose}
        style={customStyles}
        contentLabel="Sign in Modal"
      >
        <div>
          <h2 className="center">Log in with Steam</h2>
          <p>In order to perform this action you must first log in to a steam account.</p>
          <hr></hr>
          <div className="center align-elements-center">
            <a href={process.env.REACT_APP_BACKEND_URL + "/users/auth/steam"}>
              <img
                src="https://community.cloudflare.steamstatic.com/public/images/signinthroughsteam/sits_01.png"
                id="steam-button-modal-confirm"
                alt="steam-button"
                style={{"width": "200px"}}
              />
            </a>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default SignInModal;