import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// import SignInModal from "../UIElements/sign-in-modal";
import LoginOrAvatar from "./LoginOrAvatar";
import MainHeader from "./MainHeader";
import NavLinks from "./NavLinks";

import { useHttpClient } from "../../hooks/http-hook";

import "./MainNavigation.css";

// <SignInModal />

const MainNavigation = ({ user }) => {
  const [nomCount, setNomCount] = useState(0);

  const { isLoading, sendRequest } = useHttpClient()

  useEffect(() => {
    const fetchUnapprovedNominationsCount = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/collective/nominations_count", "GET", null, "include"
        );
        setNomCount(responseData.unapprovedNominationsCount);
      } catch (err) { }
    };
    fetchUnapprovedNominationsCount();
  }, [sendRequest])

  return (
    <React.Fragment>

      <MainHeader>
        <nav>
          <NavLinks nomCount={nomCount} />
        </nav>

        <Link to="/">
          <img src="https://storage.googleapis.com/callouts_tf_resources/public_images/logos/callouts.tf%20logo%20v2.0%20300px.png" className="main-navigation__logo" />
          <div className="logo_container"></div>
        </Link>

        <LoginOrAvatar user={user} />
      </MainHeader>
    </React.Fragment>
  );
};

export default MainNavigation;
