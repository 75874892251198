import React, { useState } from "react";

import "./start-banner.css";

const StartBanner = (props) => {

  const rand = Math.floor(Math.random() * 11);
  const [randomNumber] = useState(rand);
 
  let randomBannerString;
  if (randomNumber <= 4) {
    randomBannerString = "https://storage.googleapis.com/callouts_tf_resources/public_images/banner_images/Badlands.png"
  } else if (randomNumber > 4 && randomNumber <= 7) {
    randomBannerString = "https://storage.googleapis.com/callouts_tf_resources/public_images/banner_images/Granary.png"
  } else if (randomNumber > 7) {
    randomBannerString = "https://storage.googleapis.com/callouts_tf_resources/public_images/banner_images/Sultry.jpg"
  }

  return (
    <div
      id="title-hero"
      style={{
        "backgroundImage": `url(${randomBannerString})`
      }}
    >
      <div className="title-elements">
        <h1 id="title">
          callouts.tf
        </h1>
        <p id="subtitle">"All competitive map callouts in a single 8K image"</p>
      </div>
      <p className="map-count-subtitle">
        Currently hosting {props.loadedMapsData.length} maps
      </p>
    </div>
  );
};

export default StartBanner;
