import React from "react";
import { Link } from "react-router-dom";

import { FaEye } from "react-icons/fa";
import { FaDownload } from "react-icons/fa";
import { FaThumbsUp } from "react-icons/fa";
import { FaComment } from "react-icons/fa";
import ReactCountryFlag from "react-country-flag";

import "./MapItem.css";

const MapItem = (props) => {

  let usageIndicatorOverlay;
  if (props.usage.includes("4v4")) {
    usageIndicatorOverlay = "https://storage.googleapis.com/callouts_tf_resources/public_images/overlays/map_usages_overlay_4v4.png"
  } if (props.usage.includes("6v6")) {
    usageIndicatorOverlay = "https://storage.googleapis.com/callouts_tf_resources/public_images/overlays/map_usages_overlay_6v6.png";
  } if (props.usage.includes("9v9")) {
    usageIndicatorOverlay = "https://storage.googleapis.com/callouts_tf_resources/public_images/overlays/map_usages_overlay_9v9.png"
  } if (props.usage.includes("4v4") && props.usage.includes("6v6")) {
    usageIndicatorOverlay = "https://storage.googleapis.com/callouts_tf_resources/public_images/overlays/map_usages_overlay_4v4_6v6.png"
  } if (props.usage.includes("6v6") && props.usage.includes("9v9")) {
    usageIndicatorOverlay = "https://storage.googleapis.com/callouts_tf_resources/public_images/overlays/map_usages_overlay_6v6_9v9.png"
  } if (props.usage.includes("4v4") && props.usage.includes("9v9")) {
    usageIndicatorOverlay = "https://storage.googleapis.com/callouts_tf_resources/public_images/overlays/map_usages_overlay_4v4_9v9.png"
  } if (props.usage.includes("4v4") && props.usage.includes("6v6") && props.usage.includes("9v9")) {
    usageIndicatorOverlay = "https://storage.googleapis.com/callouts_tf_resources/public_images/overlays/map_usages_overlay_all.png"
  }

  function languageAbbreviation(input) {
    if (input === "Spanish") {
      return "ES"
    } else if (input === "Portuguese") {
      return "BR"
    } else {
      return "none"
    }
  }

  function numberFormat(number) {
    if (number < 1000) {
      return number
    } else if (number >= 1000 && number < 1000000) {
      return ((number / 1000).toFixed(1)) + "K"
    } else if (number >= 1000000) {
      return ((number / 1000000).toFixed(1)) + "M"
    }
  }

  return (
    <div className="col map-item-container" >
      <div className="padded-container">
        <Link to={"/maps/" + props.name}>
          <div>
            <div className="thumbnail-overlay-indicators-container">
              <img className="thumbnail-overlay-indicators" src={usageIndicatorOverlay} alt="map-usage-indicators" />
            </div>
          </div>
          <img className="thumbnail" src={props.image} alt="sample-map-thumbnail" />
        </Link>
        <div className="row">
          <Link className="col callout-title" to={"/maps/" + props.name}>
            {props.name}
          </Link>

          <h5 className="col-auto callout-date">{props.version_count} version{(props.version_count > 1) && "s"}
            {props.languages.length > 0 && props.languages.map(element => (<React.Fragment>
              <span /> <ReactCountryFlag countryCode={languageAbbreviation(element)} style={{ "width": "1rem", "height": "auto", "border": "1px outset white" }} svg />
              </React.Fragment>
            ))}</h5>


        </div>
        <div className="row metadata-row">
          <div className="col-auto callout-counter-icon">
            <FaEye />
          </div>
          <div className="col callout-counter-number">
            <p>{numberFormat(props.views)}</p>
          </div>
          <div className={`col-auto callout-counter-icon ${props.loggeduserupvotedmaps && (props.loggeduserupvotedmaps.includes(props.mapid) && "liked-map")}`}>
            <FaThumbsUp />
          </div>
          <div className="col callout-counter-number">
            <p>{numberFormat(props.upvotes)}</p>
          </div>
          <div className="col-auto callout-counter-icon">
            <FaComment />
          </div>
          <div className="col callout-counter-number">
            <p>{numberFormat(props.comments)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapItem;
