import React from "react";

import "./button-toolbar.css";

const ButtonToolbar = (props) => {
  const handleClass = (status) =>
    props.activeButtonState === status
      ? "btn btn-warning active"
      : "btn btn-secondary";

  const numberOfDozens = Math.ceil(Number(props.totalItemsFound) / 12);

  const dozensInObject = {
    length: numberOfDozens,
    0: 1, 1: 2, 2: 3, 3: 4, 4: 5, 5: 6, 6: 7, 7: 8, 8: 9, 9: 10, 10: 11, 11: 12, 12: 13, 13: 14, 14: 15
  };

  function clickButtonHandler(indicator) {
    props.setActiveButtonState(indicator);
    props.setSliceIndicators((indicator - 1) * 12);
  }

  return (
    <div className="row button-row">
      <div
        id="button-container"
        className="btn-toolbar justify-content-center"
        role="toolbar"
        aria-label="Toolbar with button groups"
      >
        <div className="btn-group me-2" role="group" aria-label="Second group">
          {Array.prototype.map.call(dozensInObject, (indicator) => (
            <button
              type="button"
              key={indicator}
              onClick={() => clickButtonHandler(indicator)}
              className={handleClass(indicator)}
            >
              {indicator}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ButtonToolbar;
