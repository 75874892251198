import React from "react";

import "./filters.css";

const Filters = (props) => {
  function searchHandler(e) {
    props.setQ(e.target.value);
    props.setSliceIndicators("0");
    props.setActiveButtonState(1);
  }

  function filterHandler(e) {
    props.setFilterParam(e.target.value);
    props.setSliceIndicators("0");
    props.setActiveButtonState(1);
  }

  function sortHandler(e) {
    props.setSortParams(e.target.value);
    props.setSliceIndicators("0");
    props.setActiveButtonState(1);
  }

  return (
    <div id="filters-area">
      <div className="row justify-content-middle center">
        <div className="col-12 col-md-6 col-lg-6 col-xl-8" data-bs-theme="dark">
          <form>
            <input
              id="search-box"
              className="form-control"
              type="search"
              placeholder=" Search..."
              aria-label="Search"
              value={props.q}
              onChange={(e) => searchHandler(e)}
            />
          </form>
        </div>
        <div className="select col-auto col-sm-4 col-md-3 col-xl-2">
          <select
            onChange={(e) => filterHandler(e)}
            className="custom-select"
            aria-label="Filter Maps by Category"
          >
            <option value="All Categories">All Categories</option>
            <option value="4v4">4v4</option>
            <option value="6v6">6v6</option>
            <option value="9v9">Highlander</option>
          </select>
        </div>
        <div className="select col-auto col-sm-4 col-md-3 col-xl-2">
          <select
            onChange={(e) => sortHandler(e)}
            className="custom-select"
            aria-label="Sort Maps by Parameter"
          >
            <option value="Sort By">Sort by...</option>
            <option value="Most Views">Most Views</option>
            <option value="Most Downloads">Most Downloads</option>
            <option value="Most Upvoted">Most Upvoted</option>
            <option value="Most Comments">Most Comments</option>
            <option value="Last Added">Last Added</option>
            <option value="First Added">First Added</option>
            <option value="A to Z">A to Z</option>
            <option value="Z to A">Z to A</option>
          </select>
        </div>

      </div>
    </div>
  );
};

export default Filters;
