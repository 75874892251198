import React, { useEffect, useState } from "react";

import LoadingSpinner from "../../../shared/components/UIElements/LoadingSpinner";

import StartBanner from "../components/start-banner";
import Legend from "../components/legend";
import MapsList from "../components/MapsList";
import Filters from "../components/filters";
import ButtonToolbar from "../components/button-toolbar";

import "./mainpage.css";

const Mainpage = ({loggeduserdeep}) => {
  const [loadedMapsData, setLoadedMapsData] = useState();
  const [isLoading, setIsLoading] = useState();

  const [q, setQ] = useState("");
  const [searchParam] = useState(["name"]);
  const [filterParam, setFilterParam] = useState("All Categories");
  const [sortParams, setSortParams] = useState("Most Views");
  const [totalItemsFound, setTotalItemsFound] = useState();
  const [sliceIndicators, setSliceIndicators] = useState("0");
  const [activeButtonState, setActiveButtonState] = useState(1);

  useEffect(() => {
    const getMaps = async () => {
      setIsLoading(true)
      await fetch(process.env.REACT_APP_BACKEND_URL + "/maps", {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          throw new Error("fetching maps has failed!");
        })
        .then((resObject) => {
          setLoadedMapsData(resObject.mapsMetadata);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getMaps();
  }, []);

  function searchAndFilter(items) {
    // eslint-disable-next-line
    return items.filter((item) => {
      if (item.mapUsage[0].includes(filterParam)) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (filterParam === "All Categories") {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      }
    });
  }

  function sortMaps(maps) {
    if (sortParams === "Sort By") {
      return maps;
    }
    if (sortParams === "Most Views") {
      return maps.sort(
        (a, b) => parseFloat(b.views) - parseFloat(a.views)
      );
    }
    if (sortParams === "Most Downloads") {
      return maps.sort(
        (a, b) => parseFloat(b.downloads) - parseFloat(a.downloads)
      );
    }
    if (sortParams === "Most Upvoted") {
      return maps.sort((a, b) => parseFloat(b.upvotes) - parseFloat(a.upvotes));
    }
    if (sortParams === "Most Comments") {
      return maps.sort((a, b) => parseFloat(b.comments.length) - parseFloat(a.comments.length));
    }
    if (sortParams === "First Added") {
      return maps.sort(
        (a, b) => new Date(a.date_created) - new Date(b.date_created)
      );
    }
    if (sortParams === "Last Added") {
      return maps.sort(
        (a, b) => new Date(b.date_created) - new Date(a.date_created)
      );
    }
    if (sortParams === "A to Z") {
      return maps.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    } else if (sortParams === "Z to A") {
      return maps.sort((a, b) => {
        if (a.name < b.name) {
          return 1;
        }
        if (a.name > b.name) {
          return -1;
        }
        return 0;
      });
    }
  }

  return (
    <React.Fragment>
      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}
      {!isLoading && loadedMapsData && (
        <StartBanner loadedMapsData={loadedMapsData} />
      )}
      <Legend />
      <div id={totalItemsFound ? "all-callouts-container" : "all-callouts-container-empty"}>

        {!isLoading && loadedMapsData && (
          <React.Fragment>
            {loadedMapsData.length > 0 && (
              <Filters
                q={q}
                setQ={setQ}
                setFilterParam={setFilterParam}
                setSortParams={setSortParams}
                setActiveButtonState={setActiveButtonState}
                setSliceIndicators={setSliceIndicators}
              />
            )}
            <div className="col">
              <h4 className="showed-maps-indicator">
                Currently found maps: {totalItemsFound}
              </h4>
            </div>

            <MapsList
              loadedMapsData={sortMaps(loadedMapsData)}
              searchAndFilter={searchAndFilter}
              setTotalItemsFound={setTotalItemsFound}
              sliceIndicators={sliceIndicators}
              loggeduserupvotedmaps={loggeduserdeep ? loggeduserdeep.userUpvoted.maps : 0}
            />
          </React.Fragment>
        )}
        <ButtonToolbar
          totalItemsFound={totalItemsFound}
          setSliceIndicators={setSliceIndicators}
          activeButtonState={activeButtonState}
          setActiveButtonState={setActiveButtonState}
        />
      </div>
    </React.Fragment>
  );
};

export default Mainpage;
