import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { RiLogoutBoxLine } from "react-icons/ri";

import TouModal from '../UIElements/sign-in-modal';

import "./LoginOrAvatar.css";

const LoginOrAvatar = ({ user, ipuser }) => {
  const [truncedUname, setTruncedUname] = useState("N/A");
  const [suspension, setSuspension] = useState("");

  function trimUsername() {
    if (user) {
      setTruncedUname(user.username);
      setSuspension("")
    }
  }

  useEffect(() => {
    trimUsername();
  })

  window.onresize = trimUsername;

  const [showTOUModal, setShowTOUModal] = useState(false);
  const showModalHandler = () => {
    setShowTOUModal(true)
  };
  const cancelModalHandler = () => {
    setShowTOUModal(false)
  };

  return (
    <div className="login-area">
      {user ? (
        <div className="container">
          <div className="row justify-content-end align-items-center">

            <div className="col-auto">
              <a href={process.env.REACT_APP_BACKEND_URL + "/users/auth/logout"}>
                <button className="btn btn-secondary btn-sm" id="logout-button">
                  <div id="logout-button">
                    <RiLogoutBoxLine />
                  </div>
                </button>
              </a>
            </div>

            <div className="col-5 col-md-6 col-lg-auto" id="username-container">
              <Link to={"/users/" + user.snakeUsername}>
                <p id="username">{truncedUname + suspension}</p>
              </Link>
            </div>

            <div className="col-auto">
              <Link to={"/users/" + user.snakeUsername}>
                <img id="avatar-image" src={user.avatar} alt="users-avatar" />
              </Link>
            </div>

          </div>
        </div>
      ) : (
        <React.Fragment>
          <TouModal
            isOpen={showTOUModal}
            onRequestClose={cancelModalHandler}
          />

          <div className="container">
            <div className="row justify-content-end">
              <div className="col-auto">
                <a href={process.env.REACT_APP_BACKEND_URL + "/users/auth/steam"}>
                  <img
                    src="https://community.cloudflare.steamstatic.com/public/images/signinthroughsteam/sits_01.png"
                    className="steam-button"
                    alt="steam-button"
                  />
                </a>
              </div>
            </div>
          </div>
        </React.Fragment>

      )
      }
    </div>
  );
};



export default LoginOrAvatar;
